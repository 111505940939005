.formCom {
  width: 70%;
}
.formCom .el-form-item {
  margin-bottom: 22px !important;
}
.formCom .dialog-footer {
  display: flex;
  justify-content: center;
}
.formCom .upload-btn {
  height: 40px;
}
.formCom .upload-btn .el-upload {
  height: 40px !important;
  border: none !important;
}
.formCom .el-tree-node__content {
  height: 2rem !important;
}
